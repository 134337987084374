/**
=========================================================
* Otis Admin PRO - v2.0.1
=========================================================

* Product Page: https://material-ui.com/store/items/otis-admin-pro-material-dashboard-react/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";

// Otis Admin PRO React components
import Stack from "@mui/material/Stack";
import MDBadge from "components/MDBadge";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import ModalUpdate from "components/Modal";
import numeral from "numeral";
import Pricing from "pages/landing-page/Pricing";
import { useEffect, useState } from "react";
import { useFlatInject } from "store";
import { Message } from "utils/message";
import { Modal } from "utils/modal";

function MobilePayUi({ children }) {
  return <MDBox>{children}</MDBox>;
}

function Payment({ isMobile }) {
  // get current user Payment status, expired date, etc
  const [user] = useFlatInject("user");
  const {
    user: userObject,
    upgradePayment,
    cancelPayment,
    resumeSubscription,
    getProrationUpgrade,
    // fetchPaymentInfo,
    downgradePayment,
    getDowngradeInfor,
  } = user;
  const {
    endAt,
    canceledAt,
    vip,
    paymentStatus,
    isDowngrading,
    nextPlan: nextPlanText,
    nextVip,
  } = userObject;

  const dateParsed = String(endAt).slice(-3) === "000" ? Number(endAt) : Number(endAt) * 1000;
  const expiredAt = new Date(dateParsed).toDateString();
  const vipStatues = vip === 1 ? "Deal Maker" : "Basic";
  const [proration, setProration] = useState("");
  const [nextPlan, setNextPlan] = useState({
    subscriptionAmount: 0,
    nextPlan: "",
    nextVip: 0,
    prorationAmount: 0,
  });

  const [open, setOpen] = useState({
    cancel: false,
    resume: false,
    upgrade: false,
    downgrade: false,
    showPlan: false,
  });

  const showCancelModal = async () => {
    try {
      await Modal.confirm({
        title: "Cancel Subscription",
        content: (
          <p>
            Are you sure you want to cancel your subscription?
            <br />
            <br />
            You will be able to continue to use your account until your subscription expires. Once
            your account expires, you have 30 days to access and export your deal data before it is
            completely removed.
          </p>
        ),
      });
      await cancelPayment();
      setOpen({
        ...open,
        cancel: false,
      });
    } catch (er) {
      console.log("cancel modal");
      setOpen({
        ...open,
        cancel: false,
      });
    }
  };

  useEffect(() => {
    if (open.cancel) {
      showCancelModal();
    }
  }, [open.cancel]);

  // const showUpgradeModal = async () => {
  //   try {
  //     const res = await getProrationUpgrade();
  //     await Modal.confirm({
  //       title: "Upgrade Subscription",
  //       content: (
  //         <MDBox>
  //           The proration is:{" "}
  //           <MDBox component="span" sx={{ fontWeight: "bold" }}>
  //             {res?.prorationAmount}
  //           </MDBox>
  //         </MDBox>
  //       ),
  //     });
  //     await upgradePayment();
  //     setOpen({
  //       ...open,
  //       upgrade: false,
  //     });
  //   } catch (er) {
  //     console.log("cancel modal");
  //     setOpen({
  //       ...open,
  //       upgrade: false,
  //     });
  //   }
  // };

  // useEffect(() => {
  //   if (open.upgrade) {
  //     showUpgradeModal();
  //   }
  // }, [open.upgrade]);

  const toggleShowPlan = () => {
    setOpen({
      ...open,
      showPlan: !open.showPlan,
    });
  };

  const callGetDataUpdateModal = async (nextMode, nextVipNumber) => {
    try {
      const res = await getProrationUpgrade(nextMode, nextVipNumber);
      setProration(res?.prorationAmount);
      setNextPlan(res);
    } catch (er) {
      console.log(er);
    } finally {
      setOpen({
        ...open,
        upgrade: true,
      });
    }
  };

  const callGetDataDowngradeModal = async (nextMode, nextVipNumber) => {
    try {
      const res = await getDowngradeInfor(nextMode, nextVipNumber);
      setNextPlan(res);
    } catch (er) {
      console.log(er);
    } finally {
      setOpen({
        ...open,
        downgrade: true,
      });
    }
  };
  const onChangePlan = async (isMonthly, isVip) => {
    const currentPlan = Number(
      `${userObject.stripeSubscriptionType === "monthly" ? 0 : 1}${userObject.vip ? 1 : 0}`
    );

    const newPlan = Number(`${isMonthly ? 0 : 1}${isVip ? 1 : 0}`);

    if (currentPlan > newPlan) {
      callGetDataDowngradeModal(isMonthly ? "monthly" : "yearly", isVip);
    } else {
      callGetDataUpdateModal(isMonthly ? "monthly" : "yearly", isVip);
    }
  };

  // useEffect(() => {
  //   if (open.upgrade) {
  //     showUpgradeModal();
  //   }
  // }, [open.upgrade]);

  // const showDowngradeModal = async () => {
  //   try {
  //     await Modal.confirm({
  //       title: "Downgrade Subscription",
  //       content: "Downgrade subscription ?",
  //     });
  //     await downgradePayment();
  //     setOpen({
  //       ...open,
  //       downgrade: false,
  //     });
  //   } catch (er) {
  //     console.log("cancel modal");
  //     setOpen({
  //       ...open,
  //       downgrade: false,
  //     });
  //   }
  // };

  // useEffect(() => {
  //   if (open.downgrade) {
  //     showDowngradeModal();
  //   }
  // }, [open.downgrade]);

  return (
    <Card id="sessions" sx={{ boxShadow: "none" }}>
      {!isMobile ? (
        <MDBox>
          <MDBox p={3} lineHeight={1} sx={{ display: "flex", justifyContent: "space-between" }}>
            {!isMobile ? (
              <MDBox mb={1}>
                <MDTypography fontWeight="normal" fontSize={24} sx={{ color: "#282828" }}>
                  Subscription and Payment
                </MDTypography>
              </MDBox>
            ) : null}
            <MDBox display="flex" alignItems="center" flexDirection="row" gap={2}>
              <Stack direction="column" spacing={2}>
                {!open.cancel && !open.resume && canceledAt === 0 && (
                  <MDButton
                    color="green"
                    disabled={isDowngrading}
                    onClick={toggleShowPlan}
                    sx={{
                      border: !open.upgrade ? "1px solid transparent !important" : null,
                      backgroundColor: open.upgrade ? "transparent !important" : null,
                      color: open.upgrade ? "#282828 !important" : null,
                      boxShadow: open.upgrade ? "none !important" : null,
                      padding: "5px 20px",
                      fontSize: "14px",
                    }}
                  >
                    Update Plan
                  </MDButton>
                )}

                {/* {open.upgrade === false ? null : (
                  <MDBox
                    display="flex"
                    alignItems="center"
                    flexdirection="row"
                    sx={{
                      alignContent: "stretch",
                      justifyContent: "space-between",
                    }}
                    gap={2}
                  >
                    <MDButton
                      color="green"
                      disable={vip}
                      onClick={() => {
                        upgradePayment().then(() => {
                          // close the dialog
                          setOpen({
                            ...open,
                            upgrade: false,
                          });
                        });
                      }}
                      sx={{ border: "1px solid transparent" }}
                    >
                      Confirm
                    </MDButton>
                    <MDButton
                      variant="outlined"
                      color="green"
                      disable={vip}
                      onClick={() => {
                        setOpen({
                          ...open,
                          upgrade: false,
                        });
                      }}
                    >
                      Cancel
                    </MDButton>
                  </MDBox>
                )}
                {open.upgrade && (
                  <MDTypography
                    variant="caption"
                    color="success"
                    sx={{
                      overflowWrap: "break-word",
                      width: "200px",
                    }}
                  >
                    Upgrade to Premium to access Deal Terms at $199 p.m. pro rata
                  </MDTypography>
                )} */}
              </Stack>
              {canceledAt === 0 ? (
                !open.upgrade && (
                  <Stack direction="column" spacing={2}>
                    <MDButton
                      color="green"
                      variant="outlined"
                      onClick={() => {
                        setOpen({
                          ...open,
                          cancel: true,
                        });
                      }}
                      disabled={open.cancel}
                      sx={{
                        padding: "5px 20px",
                        fontSize: "14px",
                        border: open.cancel ? "none !important" : "",
                        backgroundColor: open.cancel ? "transparent !important" : null,
                        color: open.cancel ? "#282828 !important" : null,
                        boxShadow: open.cancel ? "none !important" : null,
                      }}
                    >
                      Cancel Subscription
                    </MDButton>
                    {/* {open.cancel === true && (
                      <MDBox
                        display="flex"
                        alignItems="center"
                        flexdirection="row"
                        gap={2}
                        sx={{
                          alignContent: "stretch",
                          justifyContent: "space-between",
                        }}
                      >
                        <MDButton
                          color="green"
                          disable={vip}
                          onClick={() => {
                            cancelPayment().then(() => {
                              // close the dialog
                              setOpen({
                                ...open,
                                cancel: false,
                              });
                            });
                          }}
                          sx={{ border: "1px solid transparent" }}
                        >
                          Confirm
                        </MDButton>

                        <MDButton
                          variant="outlined"
                          color="green"
                          disable={vip}
                          onClick={() => {
                            setOpen({
                              ...open,
                              cancel: false,
                            });
                          }}
                        >
                          Cancel
                        </MDButton>
                      </MDBox>
                    )}
                    {open.cancel && (
                      <MDTypography
                        display="block"
                        fontWeight="regular"
                        sx={{
                          fontSize: "0.8rem",
                          overflowWrap: "break-word",
                          width: "200px",
                          color: "#137523",
                        }}
                      >
                        Cancel subscription and delete data.
                      </MDTypography>
                    )} */}
                  </Stack>
                )
              ) : open.resume === false ? (
                !open.upgrade && (
                  <MDButton
                    color="green"
                    disable={vip}
                    onClick={() => {
                      setOpen({
                        ...open,
                        resume: true,
                      });
                    }}
                    sx={{
                      padding: "5px 20px",
                      fontSize: "14px",
                      border: "1px solid transparent",
                    }}
                  >
                    {`${
                      userObject.stripeSubscriptionType === "canceled"
                        ? "Renew Subscription"
                        : "Resume Subscription"
                    }`}
                  </MDButton>
                )
              ) : (
                <MDBox
                  display="flex"
                  alignItems="center"
                  flexdirection="row"
                  gap={2}
                  sx={{
                    alignContent: "stretch",
                    justifyContent: "space-between",
                  }}
                >
                  <MDButton
                    color="green"
                    disable={vip}
                    onClick={() => {
                      resumeSubscription().then(() => {
                        // close the dialog
                        setOpen({
                          ...open,
                          resume: false,
                        });
                      });
                    }}
                    sx={{ border: "1px solid transparent" }}
                  >
                    Confirm
                  </MDButton>
                  <MDButton
                    variant="outlined"
                    color="green"
                    disable={vip}
                    onClick={() => {
                      setOpen({
                        ...open,
                        resume: false,
                      });
                    }}
                  >
                    Cancel
                  </MDButton>
                </MDBox>
              )}
            </MDBox>
          </MDBox>
          {open.showPlan && (
            <MDBox pb={3} px={3} sx={{ overflow: "auto" }}>
              <MDBox
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                width={{ xs: "max-content", sm: "100%" }}
              >
                <Pricing isMobile={isMobile} isChangeSubscription onChangePlan={onChangePlan} />
              </MDBox>
            </MDBox>
          )}
          <MDBox pb={3} px={3} sx={{ overflow: "auto" }}>
            <MDBox
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              width={{ xs: "max-content", sm: "100%" }}
            >
              <MDBox display="flex" alignItems="center">
                <MDTypography
                  display="block"
                  variant="button"
                  fontWeight="regular"
                  sx={{ color: "#282828", minWidth: "300px" }}
                >
                  {`Your Subscription will ${canceledAt === 0 ? "renew on" : "end on"}`}
                </MDTypography>

                {userObject.stripeSubscriptionType !== "canceled" && (
                  <MDTypography
                    display="block"
                    variant="button"
                    fontWeight="regular"
                    sx={{ color: "#137523" }}
                  >
                    {expiredAt}
                    {isDowngrading
                      ? `, ${nextVip ? "Deal Maker" : "Basic"} Subscription (${
                          nextPlanText === "yearly"
                            ? "Annual"
                            : nextPlanText === "monthly"
                            ? "Monthly"
                            : ""
                        })`
                      : ""}
                  </MDTypography>
                )}
              </MDBox>
              <MDBox display="flex" alignItems="center" gap={2}>
                {canceledAt === 0 ? (
                  <>
                    <MDBadge
                      variant=""
                      badgeContent="Active"
                      container
                      sx={{
                        fontSize: "12px",
                        color: "#137523 !important",
                        backgroundColor: "rgba(26, 147, 46, 0.18) !important",
                        borderRadius: "20px",
                      }}
                      fontRegular
                    />
                    <MDBadge
                      variant=""
                      badgeContent="Auto-renew"
                      fontWeight="regular"
                      sx={{
                        fontSize: "12px !important",
                        color: "#137523 !important",
                        backgroundColor: "rgba(26, 147, 46, 0.18) !important",
                        borderRadius: "20px",
                      }}
                      fontRegular
                      container
                    />
                  </>
                ) : (
                  <>
                    <MDBadge
                      variant=""
                      badgeContent="Cancelled"
                      sx={{
                        fontSize: "12px !important",
                        color: "#3B3B3B !important",
                        backgroundColor: "rgba(145, 145, 145, 0.18) !important",
                        borderRadius: "20px",
                      }}
                      container
                      fontRegular
                    />

                    {/* <MDBadge
                      variant=""
                      badgeContent="Renew-stoped"
                      sx={{
                        fontSize: "12px !important",
                        color: "#3B3B3B !important",
                        backgroundColor: "rgba(145, 145, 145, 0.18) !important",
                        borderRadius: "20px",
                      }}
                      container
                      fontRegular
                    /> */}
                  </>
                )}
              </MDBox>
            </MDBox>
            <Divider />
            <MDBox
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              width={{ xs: "max-content", sm: "100%" }}
            >
              <MDBox display="flex" alignItems="center">
                <MDTypography
                  display="block"
                  variant="button"
                  fontWeight="regular"
                  sx={{ color: "#282828", minWidth: "300px" }}
                >
                  Your current membership level
                </MDTypography>
                {userObject.stripeSubscriptionType !== "canceled" && (
                  <MDTypography
                    sx={{
                      color: "#137523",
                      gap: "6px",
                    }}
                    display="flex"
                    variant="button"
                    fontWeight="regular"
                  >
                    {vipStatues} Subscription{" "}
                    <MDTypography
                      sx={{
                        color: "#137523",
                      }}
                      display="block"
                      variant="button"
                      fontWeight="regular"
                    >{` (${
                      userObject.stripeSubscriptionType === "yearly"
                        ? "Annual"
                        : userObject.stripeSubscriptionType === "monthly"
                        ? "Monthly"
                        : ""
                    })`}</MDTypography>
                  </MDTypography>
                )}
              </MDBox>
            </MDBox>
          </MDBox>
        </MDBox>
      ) : (
        <MobilePayUi>
          {/* <MDBox p={3} lineHeight={1} sx={{ display: "flex", justifyContent: "space-between" }}>
            <MDBox display="flex" alignItems="center" flexDirection="row" gap={2}>
              <Stack direction="column" spacing={2}>
                {!vip && !open.cancel && !open.resume && (
                  <MDButton
                    color="green"
                    disabled={vip || open.upgrade}
                    onClick={() => {
                      setOpen({
                        ...open,
                        upgrade: true,
                      });
                    }}
                    sx={{
                      border: !open.upgrade ? "1px solid transparent !important" : null,
                      backgroundColor: open.upgrade ? "transparent !important" : null,
                      color: open.upgrade ? "#282828 !important" : null,
                      boxShadow: open.upgrade ? "none !important" : null,
                      padding: "5px 20px",
                      fontSize: "14px",
                    }}
                  >
                    Upgrade Subscription
                  </MDButton>
                )}
                {open.upgrade === false ? null : (
                  <MDBox
                    display="flex"
                    alignItems="center"
                    flexdirection="row"
                    sx={{
                      alignContent: "stretch",
                      justifyContent: "space-between",
                    }}
                    gap={2}
                  >
                    <MDButton
                      color="green"
                      disable={vip}
                      onClick={() => {
                        upgradePayment().then(() => {
                          // close the dialog
                          setOpen({
                            ...open,
                            upgrade: false,
                          });
                        });
                      }}
                      sx={{ border: "1px solid transparent" }}
                    >
                      Confirm
                    </MDButton>
                    <MDButton
                      variant="outlined"
                      color="green"
                      disable={vip}
                      onClick={() => {
                        setOpen({
                          ...open,
                          upgrade: false,
                        });
                      }}
                    >
                      Cancel
                    </MDButton>
                  </MDBox>
                )}
                {open.upgrade && (
                  <MDTypography
                    variant="caption"
                    color="success"
                    sx={{
                      overflowWrap: "break-word",
                      width: "200px",
                    }}
                  >
                    Upgrade to Premium to access Deal Terms at $199 p.m. pro rata
                  </MDTypography>
                )}
              </Stack>
            </MDBox>
          </MDBox> */}
          <MDBox pb={3} sx={{ overflow: "auto" }}>
            <MDBox
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              alignItems="flex-start"
              width={{ xs: "max-content", sm: "100%", gap: "20px" }}
            >
              <MDBox
                display="flex"
                alignItems="flex-start"
                flexDirection="column"
                justifyContent="space-between"
                gap="20px"
              >
                <MDTypography
                  display="block"
                  variant="button"
                  fontWeight="regular"
                  sx={{ color: "#282828", minWidth: "300px" }}
                >
                  {`Your Subscription will ${canceledAt === 0 ? "renew on" : "end on"}`}
                </MDTypography>

                {userObject.stripeSubscriptionType !== "canceled" && (
                  <MDTypography
                    display="block"
                    variant="button"
                    fontWeight="regular"
                    sx={{ color: "#137523" }}
                  >
                    {expiredAt}
                    {isDowngrading
                      ? `, Basic Subscription (${
                          userObject.stripeSubscriptionType === "yearly"
                            ? "Annual"
                            : userObject.stripeSubscriptionType === "monthly"
                            ? "Monthly"
                            : ""
                        })`
                      : ""}
                  </MDTypography>
                )}
              </MDBox>
              <MDBox display="flex" alignItems="flex-start" gap={2}>
                {canceledAt === 0 ? (
                  <>
                    <MDBadge
                      variant=""
                      badgeContent="Active"
                      container
                      sx={{
                        fontSize: "12px",
                        color: "#137523 !important",
                        backgroundColor: "rgba(26, 147, 46, 0.18) !important",
                        borderRadius: "20px",
                      }}
                      fontRegular
                    />
                    <MDBadge
                      variant=""
                      badgeContent="Auto-renew"
                      fontWeight="regular"
                      sx={{
                        fontSize: "12px !important",
                        color: "#137523 !important",
                        backgroundColor: "rgba(26, 147, 46, 0.18) !important",
                        borderRadius: "20px",
                      }}
                      fontRegular
                      container
                    />
                  </>
                ) : (
                  <>
                    <MDBadge
                      variant=""
                      badgeContent="Cancelled"
                      sx={{
                        fontSize: "12px !important",
                        color: "#3B3B3B !important",
                        backgroundColor: "rgba(145, 145, 145, 0.18) !important",
                        borderRadius: "20px",
                      }}
                      container
                      fontRegular
                    />

                    {/* <MDBadge
                      variant=""
                      badgeContent="Renew-stoped"
                      sx={{
                        fontSize: "12px !important",
                        color: "#3B3B3B !important",
                        backgroundColor: "rgba(145, 145, 145, 0.18) !important",
                        borderRadius: "20px",
                      }}
                      container
                      fontRegular
                    /> */}
                  </>
                )}
              </MDBox>
            </MDBox>
            <Divider sx={{ width: "100%" }} />
            <MDBox
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              alignItems="center"
              width={{ xs: "max-content", sm: "100%" }}
            >
              <MDBox display="flex" alignItems="flex-start" flexDirection="column" gap="20px">
                <MDTypography
                  display="block"
                  variant="button"
                  fontWeight="regular"
                  sx={{ color: "#282828", minWidth: "300px" }}
                >
                  Your current membership level
                </MDTypography>
                {userObject.stripeSubscriptionType !== "canceled" && (
                  <MDTypography
                    sx={{
                      color: "#137523",
                      gap: "6px",
                    }}
                    display="flex"
                    variant="button"
                    fontWeight="regular"
                  >
                    {vipStatues} Subscription{" "}
                    <MDTypography
                      sx={{
                        color: "#137523",
                      }}
                      display="block"
                      variant="button"
                      fontWeight="regular"
                    >{` (${
                      userObject.stripeSubscriptionType === "yearly"
                        ? "Annual"
                        : userObject.stripeSubscriptionType === "monthly"
                        ? "Monthly"
                        : ""
                    })`}</MDTypography>
                  </MDTypography>
                )}
              </MDBox>
            </MDBox>
            <Divider sx={{ width: "100%" }} />
          </MDBox>
          <MDBox sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
            {!open.cancel && !open.resume && canceledAt === 0 && (
              <MDButton
                color="green"
                disabled={isDowngrading}
                onClick={toggleShowPlan}
                sx={{
                  border: !open.upgrade ? "1px solid transparent !important" : null,
                  backgroundColor: open.upgrade ? "transparent !important" : null,
                  color: open.upgrade ? "#282828 !important" : null,
                  boxShadow: open.upgrade ? "none !important" : null,
                  padding: "5px 20px",
                  fontSize: "16px",
                  width: "100%",
                  height: "39px",
                }}
              >
                Update Plan
              </MDButton>
            )}

            {canceledAt === 0 ? (
              !open.upgrade && (
                <Stack direction="column" spacing={2}>
                  <MDButton
                    color="green"
                    variant="outlined"
                    onClick={() => {
                      setOpen({
                        ...open,
                        cancel: true,
                      });
                    }}
                    disabled={open.cancel}
                    sx={{
                      padding: "5px 20px",
                      fontSize: "16px",
                      border: open.cancel ? "none !important" : "",
                      backgroundColor: open.cancel ? "transparent !important" : null,
                      color: open.cancel ? "#282828 !important" : null,
                      boxShadow: open.cancel ? "none !important" : null,
                    }}
                  >
                    Cancel Subscription
                  </MDButton>
                  {/* {open.cancel === true && (
                    <MDBox display="flex" flexDirection="column" gap="20px">
                      <MDTypography
                        fontSize="14px"
                        fontWeight="400"
                        mb="4px"
                        alignSelf="center"
                        sx={{ color: "#282828 !important" }}
                      >
                        Cancel subscription and delete data
                      </MDTypography>
                      <MDButton
                        color="green"
                        variant="outlined"
                        disable={vip}
                        onClick={() => {
                          cancelPayment().then(() => {
                            // close the dialog
                            setOpen({
                              ...open,
                              cancel: false,
                            });
                          });
                        }}
                        // variant="outlined"
                        sx={{ border: "1px solid !important" }}
                      >
                        Yes, cancel my subscription
                      </MDButton>

                      <MDButton
                        // variant="outlined"
                        color="green"
                        sx={{ border: "1px solid transparent" }}
                        disable={vip}
                        onClick={() => {
                          setOpen({
                            ...open,
                            cancel: false,
                          });
                        }}
                      >
                        No, thanks
                      </MDButton>
                    </MDBox>
                  )} */}
                  {/* {open.cancel && (
                    <MDTypography
                      display="block"
                      fontWeight="regular"
                      sx={{
                        fontSize: "0.8rem",
                        overflowWrap: "break-word",
                        width: "200px",
                        color: "#137523",
                      }}
                    >
                      Cancel subscription and delete data.
                    </MDTypography>
                  )} */}
                </Stack>
              )
            ) : open.resume === false ? (
              !open.upgrade && (
                <MDButton
                  color="green"
                  width="100%"
                  disable={vip}
                  onClick={() => {
                    setOpen({
                      ...open,
                      resume: true,
                    });
                  }}
                  sx={{
                    padding: "5px 20px",
                    width: "100% !important",
                    fontSize: "14px",
                    border: "1px solid transparent",
                  }}
                >
                  Resume Subscription
                </MDButton>
              )
            ) : (
              <MDBox display="flex" flexDirection="column" gap={2}>
                <MDButton
                  color="green"
                  disable={vip}
                  onClick={() => {
                    resumeSubscription().then(() => {
                      // close the dialog
                      setOpen({
                        ...open,
                        resume: false,
                      });
                    });
                  }}
                  sx={{ border: "1px solid transparent" }}
                >
                  Confirm
                </MDButton>
                <MDButton
                  variant="outlined"
                  color="green"
                  disable={vip}
                  onClick={() => {
                    setOpen({
                      ...open,
                      resume: false,
                    });
                  }}
                >
                  Cancel
                </MDButton>
              </MDBox>
            )}
          </MDBox>
        </MobilePayUi>
      )}
      <ModalUpdate
        open={open.upgrade}
        setOpen={setOpen}
        title={`Upgrade to a ${nextPlan.nextPlan} ${
          Number(nextPlan.nextVip) ? "Deal Maker" : "Basic"
        } account.`}
        content={
          <p>
            Payment will be deducted immediately using your current payment method.
            <br />
            <br />
            You will be charged a pro rata amount today of ${numeral(proration).format("0,0")}. Your
            regular subscription will then be updated to $
            {numeral(nextPlan.subscriptionAmount).format("0,0")} {nextPlan.nextPlan}.
          </p>
        }
        confirmFunction={async () => {
          await upgradePayment(nextPlan.nextPlan, nextPlan.nextVip, () => {
            Message.success("Subscription updated successfully");
            setOpen({
              ...open,
              upgrade: false,
            });
          });
        }}
        field="upgrade"
      />
      <ModalUpdate
        open={open.downgrade}
        setOpen={setOpen}
        title={`Switch to a ${
          Number(nextPlan.nextVip) ? "Deal Maker" : "Basic"
        } account and continue to manage your deals.`}
        content={
          <p>
            Your subscription will switch to a {Number(nextPlan.nextVip) ? "Deal Maker" : "Basic"}{" "}
            account when your subscription next renews.
            <br />
            <br />
            Your ongoing payments will be updated to $
            {numeral(nextPlan.subscriptionAmount).format("0,0")} per
            {` ${
              nextPlan.nextPlan ? nextPlan.nextPlan?.slice(0, nextPlan.nextPlan.length - 2) : ""
            }`}
            .
          </p>
        }
        confirmFunction={() =>
          downgradePayment(nextPlan.nextPlan, nextPlan.nextVip, () => {
            setOpen({
              ...open,
              showPlan: false,
              downgrade: false,
            });
            Message.success("Subscription updated successfully");
          })
        }
        confirmText="Confirm"
        cancelText="Cancel"
        field="downgrade"
      />
    </Card>
  );
}

export default Payment;
